import React from "react";
import { Anchor } from "../../atoms/Anchor";
import { Text, Types } from "../../atoms/Text";
import Block from "../../molecules/Block";
import { BlockTitle } from "../../molecules/BlockTitle";
import { withTranslation } from 'react-i18next';

class SkillsBlockTelephone extends React.Component {
    render() {
        const { t } = this.props;
        const hardSkills = t('skills.hardSkills.items', { returnObjects: true });
        const softSkills = t('skills.softSkills.items', { returnObjects: true });

        return (
            <Block style={{display: 'block'}}>
                <Anchor id='skills'/>
                <BlockTitle>
                    {t('skills.header')}
                </BlockTitle>
                <div style={{display: 'flex', 
                            width: '100%',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                            alignItems: 'center'}}>
                    <div style={{display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'left'}}>


                        <div style={{}}>
                            <Text type={Types.MEDIUM} style={{fontSize: '21px', color: '#3B6EF6'}}>
                                {t('skills.hardSkills.header')}
                            </Text>
                            <div>
                                <Text type={Types.REGULAR}>
                                <ul>
                                    {hardSkills.map((skill, index) => (
                                        <li key={index}>
                                        <b>{skill.name}</b> - {skill.technologies}
                                        </li>
                                    ))}
                                </ul>
                                </Text>
                            </div>
                        </div>
                        <div style={{}}>
                            <Text type={Types.MEDIUM} style={{fontSize: '21px', color: '#3B6EF6'}}>
                                {t('skills.softSkills.header')}
                            </Text>
                            <div>
                                <Text type={Types.REGULAR}>
                                <ul>
                                    {softSkills.map((skill, index) => (
                                        <li key={index}>
                                        <b>{skill.name}</b> - {skill.description}
                                        </li>
                                    ))}
                                </ul>
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </Block>
        )
    }
}

export default withTranslation()( SkillsBlockTelephone );