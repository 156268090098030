import React from "react";
import { Link } from "../../atoms/Link";
import { Text, Types } from "../../atoms/Text";
import { withTranslation } from 'react-i18next';

class Header extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <div style={{ 
                color: 'white',
                backgroundColor: '#323449',
                fontSize: '1.1em',
                height: '50px',
                position: 'sticky',
                top: 0,
                }}>
                <div style={{marginLeft: '25%',
                            marginRight: '25%',
                            paddingTop: '15px',
                            fontFamily: 'TTNormsMedium',
                            }}>
                    <div style={{display: 'flex',
                                justifyContent: 'space-around',
                                }}>
                        <div>
                            <Text type={Types.MEDIUM}>
                                <Link to='#about' newWindow={false}>
                                    {t('header.about')}
                                </Link>
                            </Text>
                        </div>
                        <div>
                            <Text type={Types.MEDIUM}>
                                <Link to='#experience' newWindow={false}>
                                    {t('header.experience')}
                                </Link>
                            </Text>
                        </div>  
                        <div>
                            <Text type={Types.MEDIUM}>
                                <Link to='#skills' newWindow={false}>
                                    {t('header.skills')}
                                </Link>
                            </Text>
                        </div>    
                        {/* <div> */}
                            {/* Проекты */}
                        {/* </div>      */}
                        <div>
                            <Text type={Types.MEDIUM}>
                                <Link to='#links' newWindow={false}>
                                    {t('header.links')}
                                </Link>
                            </Text>
                        </div>           
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()( Header );