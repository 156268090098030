import React from "react";
import { Title } from "../../atoms/Title";
import picture from "../../../assets/imgs/picture.png";
import Block from "../../molecules/Block";
import { Text, Types } from "../../atoms/Text";
import { BlockTitle } from "../../molecules/BlockTitle";
import { DownloadResumeButton } from "../../molecules/DownloadResumeButton";
import { ContactMeButton } from "../../molecules/ContactMeButton";
import { Anchor } from "../../atoms/Anchor";
import { withTranslation } from 'react-i18next';

class AboutBlockTelephone extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <Block>
                <Anchor id='about'/>
                <BlockTitle>
                    {t('about.header')}
                </BlockTitle>
                <img src={picture} alt='Main' style={{
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        maxWidth: '250px', 
                                        maxHeight: '250px',
                                        borderRadius: '100px',
                                        height: 'auto',
                                        width: 'auto',
                                        objectFit: 'cover',
                                        marginBottom: '20px'}}/>     
                <div style={{display: 'flex',
                            justifyContent: 'space-between', 
                            flexDirection: 'column', 
                            alignItems: 'center',
                            margin: '0px 3%',}}>
                    <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                margin: '25px 0px',
                            }}>
                            <Title style={{marginBottom: '15px'}}>
                                {t('about.introduction.hello')}
                            </Title>
                            <Text type={Types.REGULAR}>
                                {t('about.introduction.text')}
                            </Text>
                    </div>


                    <div style={{display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            margin: '5px 0px',
                    }}>
                        <Title style={{marginBottom: '15px'}}>
                            {t('about.resume.intresting')}
                        </Title>
                        <Text type={Types.REGULAR}>
                            {t('about.resume.text')}
                        </Text>
                        

                        <DownloadResumeButton text={t('about.resume.download')}/>
                        <ContactMeButton text={t('about.resume.contactMe')}/>

                    </div>
                </div>
            </Block>
        )
    }
}

export default withTranslation()( AboutBlockTelephone );