import React from "react";
import Block from "../../molecules/Block";
import { Arrow } from "../../atoms/Arrow";
import { BlockTitle } from "../../molecules/BlockTitle";
import { ExperienceRow } from "../../molecules/ExperienceRow";
import { Anchor } from "../../atoms/Anchor";
import { withTranslation } from 'react-i18next';

class ExperienceBlock extends React.Component {
    render() {
        const { t } = this.props;

        const items = t('experience.items', { returnObjects: true });
        return (
            <Block>
                <Anchor id='experience'/>
                <BlockTitle>
                    <div>
                        {t('experience.header')}
                    </div>
                </BlockTitle>
                <div>
                    <div id="arrowStart"></div>
                </div>

                {items.map((experience, index) => (
                    <ExperienceRow
                        key={index}
                        dateText={experience.date}
                        titleText={experience.header}
                        blockText={experience.text}
                        emoji={experience.emoji } 
                        textOnRightSide={index % 2 === 1}
                    />
                ))}
                <Arrow/>
                <div id="arrowEnd"/>
            </Block>
        );
    };
}

export default withTranslation()( ExperienceBlock );