import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en/translation.json';
import ruTranslation from './locales/ru/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation },
      ru: { translation: ruTranslation }
    },
    detection: {
      order: ['querystring', 'navigator', 'htmlTag', 'localStorage', 'sessionStorage', 'path', 'subdomain'],
      caches: ['localStorage'],
      lookupQuerystring: 'lang'
    },
    fallbackLng: 'en',
    interpolation: { escapeValue: false }
  });

export default i18n;