import React from "react";
import { Text, Types } from "../../atoms/Text";
import { withTranslation } from 'react-i18next'

class GreetingsBlock extends React.Component {
    render() {
        const { t } = this.props;

        return (
           <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'column',
                margin: '20px 0',
           }}>
                <Text type={Types.MEDIUM}
                        style={{color: '#3B6EF6', fontSize: '35px'}}>
                    {t('greetings.header')}

                </Text>
                <Text type={Types.REGULAR} style={{fontSize: '20px', padding: '16px 0',}}>
                    {t('greetings.text')}
                </Text>
                <hr style={{backgroundColor: '#0B266D',
                            height: '1px',
                            border: 'none',
                            width: '100%'}}/>
           </div>
        )
    }
}

export default withTranslation()( GreetingsBlock );