import React from "react";
import Block from "../../molecules/Block";
import { BlockTitle } from "../../molecules/BlockTitle";
import { Anchor } from "../../atoms/Anchor";
import { ExperienceRowMobile } from "../../molecules/ExperienceRowMobile";
import { withTranslation } from 'react-i18next';

class ExperienceBlockTelephone extends React.Component {
    render() {
        const { t } = this.props;

        const items = t('experience.items', { returnObjects: true });
        return (
            <Block style={{}}>
                <Anchor id='experience'/>
                <BlockTitle>
                    <div>
                        {t('experience.header')}
                    </div>
                </BlockTitle>
                {items.map((experience, index) => (
                    <ExperienceRowMobile
                        key={index}
                        dateText={experience.date}
                        titleText={experience.header}
                        blockText={experience.text}
                    />
                ))}
            </Block>
        )
    }
}

export default withTranslation()( ExperienceBlockTelephone );