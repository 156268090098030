import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Block from "../../molecules/Block";
import { BlockTitle } from "../../molecules/BlockTitle";
import { faTelegramPlane, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faFile, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { Text, Types } from "../../atoms/Text";
import { DownloadResume } from "../../atoms/DownloadResume";
import { LinkRow } from "../../molecules/LinkRow";
import { Anchor } from "../../atoms/Anchor";
import { withTranslation } from 'react-i18next';


class LinksBlock extends React.Component {
    render() {
        const { t } = this.props;

        return (
            <Block style={{display: 'block'}}>
                <Anchor id='links'/>
                <BlockTitle>
                    {t('links.header')}
                </BlockTitle>
                <div style={{display: 'flex', 
                            width: '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            fontSize: '18px',
                            }}>
                    <div style={{width: '35%',
                                }}>
                        <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <div onClick={DownloadResume} style={{cursor: 'pointer'}} className='links'>
                                <Text type={Types.REGULAR} style={{cursor: 'pointer'}}>
                                    <FontAwesomeIcon icon={faFile} style={{color: '#3B6EF6'}}/> &#8203; {t('links.resume')}
                                </Text>
                            </div>
                            <LinkRow linkTo='https://github.com/nasibullin-erik'>
                                <FontAwesomeIcon icon={faGithub} style={{color: '#3B6EF6'}}/> {t('links.mainGithubRepo')}
                            </LinkRow>
                            <LinkRow linkTo='https://github.com/Samurai9'>
                                <FontAwesomeIcon icon={faGithub} style={{color: '#3B6EF6'}}/> {t('links.studyGithubRepo')}
                            </LinkRow>
                        </div>
                    </div>
                    <div style={{width: '35%',
                                textAlign: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center'}}>
                        <LinkRow linkTo='https://t.me/Erik_Nasibullin'>
                            <FontAwesomeIcon icon={faTelegramPlane} style={{color: '#3B6EF6'}}/> {t('links.telegram')}
                        </LinkRow>
                        <LinkRow linkTo='mailto: nasibullinei@internet.ru'>
                            <FontAwesomeIcon icon={faEnvelope} style={{color: '#3B6EF6'}}/> {t('links.mail')}
                        </LinkRow>
                        <LinkRow linkTo='https://2gis.ru/kazan'>
                            <FontAwesomeIcon icon={faLocationDot} style={{color: '#3B6EF6'}}/> &#8203; {t('links.geolocation')}
                        </LinkRow>
                    </div>
                </div>                      
            </Block>
        )
    }
}


export default withTranslation()( LinksBlock );